import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { HiAcademicCap } from "react-icons/hi";
import ivms from "../../assets/images/Backgrounds/ivms4200-banner.png"
import smart from "../../assets/images/Backgrounds/smartpss-banner.png"
import outlook from "../../assets/images/Backgrounds/outlook-banner.png"
import teams from "../../assets/images/Backgrounds/teams-banner.png"
import drive from "../../assets/images/Backgrounds/drive-banner.png"
import dashboard from "../../assets/images/Backgrounds/dashboard-banner.png"

const categories = [
  {
    id: 1,
    title: "Outlook",
    description: "Aprende a usar el correo electrónico.",
    image:
      "https://support.content.office.net/es-es/media/d8369889-04df-4721-831d-e0490e10aaeb.png",
    backgroundImage: outlook,
  },
  {
    id: 2,
    title: "Teams",
    description: "Gestiona reuniones y comunicación.",
    image:
      "https://support.content.office.net/es-es/media/d09f346e-3b3f-4bbc-b4cd-ad6f9df1ab6e.png",
      backgroundImage: teams,
  },
  {
    id: 2,
    title: "GoogleDrive",
    description: "Gestiona reuniones y comunicación.",
    image:
      "https://static.vecteezy.com/system/resources/previews/017/395/378/original/google-drive-icons-free-png.png",
      backgroundImage: drive,
    },
  {
    id: 3,
    title: "IVMS4200",
    description: "Software de Monitoreo.",
    image: "https://www.varnet.cz/zbozi-obrazek/2101-042-ivms-4200-lite.jpg",
    backgroundImage: ivms,
  },
  {
    id: 4,
    title: "SMARTPSS",
    description: "Software de Monitoreo.",
    image:
      "https://www.nesabamedia.com/wp-content/uploads/2021/10/Dahua-SmartPSS-Logo-2-680x350.png",
      backgroundImage:smart,
  },
  {
    id: 4,
    title: "Dashboard",
    description: "Software de Monitoreo.",
    image:
      "http://localhost:3000/static/media/Logo-short.8c7249d4812f6e360a12.png",
      backgroundImage: dashboard,
  },
];

const Resources = () => {
    const navigate = useNavigate();
  return (
    <div className="py-5 px-8 container-courses bg-hero-app">
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "25px", // Espacio debajo del título
          color: "white", // Color del texto principal
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <HiAcademicCap
            style={{ fontSize: "2rem", color: "#c2a500", marginRight: "10px" }}
          />{" "}
          {/* Icono dorado */}
          <span>Recursos de Aprendizaje</span>
        </Box>
        <Divider
          sx={{
            flex: 1,
            marginLeft: "10px",
            borderColor: "#c2a500",
            borderWidth: 1,
          }}
        />{" "}
        {/* Línea dorada a la derecha */}
      </Typography>

      <Grid container  gap={5} justifyContent="start" my="30px">
        {categories.map((category) => (
          <article class="card-course">
            <section
              class="card__hero"
              style={{
                backgroundImage: `url(${category.backgroundImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <p class="card__job-title">Senior Backend Engineer</p>
            </section>

            <footer class="card__footer">
              <div class="card__job-summary">
                <div class="card__job-icon">
                  <img
                    src={category.image}
                    alt=""
                    className="object-cover h-10 w-10"
                  />
                </div>
                <div class="card__job">
                  <p class="card__job-title">
                    {category.title} <br />
                  </p>
                </div>
              </div>

              <button onClick={()=> navigate(`/dashboard/resources/${category.title
            .toLowerCase()
            .replace(/\s+/g, "-")}`)}
          to={`/dashboard/resources/${category.title
            .toLowerCase()
            .replace(/\s+/g, "-")}`} button-black class="button button-black" >view</button>
            </footer>
          </article>
        ))}
      </Grid>
    </div>
  );
};

export default Resources;
