import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";

const VideosComponent = ({ videos, colorTitle }) => (
  <>
    {/*   <Typography variant="h4" sx={{ mt: 3 }} gutterBottom>
  Videos
</Typography> */}
    <Grid container spacing={4}>
      {videos.map((video) => (
        <Grid item xs={12} sm={6} md={3} key={video.id}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
            whileHover={{ scale: 1.05 }}
          >
            <Card elevation={4}>
              <CardMedia
                component="iframe"
                height="150"
                src={video.videoUrl}
                title={video.title}
                style={{ border: "none" }}
                allow="fullscreen" // Permite fullscreen en navegadores compatibles
                allowFullScreen // Asegura que la funcionalidad de fullscreen esté habilitada
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {video.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2, // Número de líneas que quieres mostrar
                    lineClamp: 2, // Para navegadores que no usan Webkit
                  }}
                >
                  {video.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" sx={{color:colorTitle}}>
                  Watch Now
                </Button>
              </CardActions>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  </>
);

export default VideosComponent;
