import React, { useState, useEffect } from "react";
import {
  useParams,
  useLocation,
  useNavigate,
  Routes,
  Route,
} from "react-router-dom";
import { Tabs, Tab, Container, Box, Badge, Typography } from "@mui/material";
import VideosComponent from "../components/VideoComponent";
import ArticlesComponent from "../components/ArticlesComponent";
import Logo from "../../assets/images/Logos/Logo-short.png";
import FilesResourcesComponent from "../components/FilesResourcesComponent";
import { Article, FileCopy, VideoCameraFront } from "@mui/icons-material";

const resourcesData = {
  Teams: {
    Videos: [
      {
        id: "1",
        title: "Descarga Microsoft Teams en PC, MAC y celular",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/teams/Descarga+Microsoft+Teams+en+PC%2C+MAC+y+celular.mp4",
      },
      {
        id: "2",
        title: "COMO ENTRAR a una REUNION en microsoft TEAMS en CELULAR",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/teams/COMO+ENTRAR+a+una+REUNION+en+microsoft+TEAMS+en+CELULAR.mp4",
      },
      {
        id: "3",
        title: "Entrar a una reunión de Microsoft Teams como Invitado",
        description: `1. Haz clic en tu foto de perfil y selecciona 'Configuración'.
        2. Ve a la pestaña 'Notificaciones'.
        3. Personaliza las notificaciones según tus preferencias.`,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/teams/Entrar+a+una+reuni%C3%B3n+de+Microsoft+Teams+como+Invitado+.mp4",
      },
      
      {
        id: "5",
        title: "Dónde se guardan las reuniones grabadas en Teams",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/teams/D%C3%B3nde+se+guardan+las+reuniones+grabadas+en+Microsoft+Teams.mp4",
      }
    ],
    /* Articles: [
      {
        id: "1",
        title: "Cómo gestionar equipos en Microsoft Teams",
        content:
          "Microsoft Teams es una herramienta esencial para la colaboración en equipo. Aquí te mostramos cómo gestionar equipos de manera eficiente: \n1. Crear un equipo: Ve a 'Equipos' y selecciona 'Unirse o crear un equipo'. Elige 'Crear un equipo' y define un nombre, descripción y tipo (privado o público). \n2. Agregar miembros: Haz clic en 'Más opciones' (tres puntos) junto al equipo, selecciona 'Administrar equipo' y agrega miembros por nombre o correo electrónico. \n3. Configurar permisos: Desde 'Administrar equipo', puedes definir permisos para miembros, como permitir menciones o la creación de canales. \n4. Gestionar canales: Crea canales para organizar las conversaciones por proyectos o temas específicos. Personaliza cada canal con pestañas útiles como 'Archivos' o 'Wiki'. \nConsulta más detalles en https://support.microsoft.com/es-es/teams.",
      }
    ],*/
    Files:[
      {
        id: "1",
        name: "Como usar Microsoft TEAMS",
        type: "PDF",
        url: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/teams/Como+usar+Microsoft+TEAMS.pdf",
      }
    ] 
  },
  Outlook: {
    Videos: [
      {
        id: "1",
        title: "Crear y enviar un correo electrónico",
        description: `1. Seleccione Inicio > Nuevo correo electrónico.
        2. Agregue destinatarios, un tema y un mensaje en el cuerpo del correo electrónico.
        3. Seleccione Enviar.`,
        videoUrl:
          "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/outlook/Crear+Enviar+y+Responder+un+Correo.mp4",
      },
      {
        id: "2",
        title: "Enviar y recibir datos adjuntos",
        description: `
1. En la sección Insertar de la cinta de opciones, seleccione Firma > Firmas.
2. Seleccione Nueva, escriba un nombre para la firma y haga clic en Aceptar.
3. En Editar firma, escriba la firma y dele formato como quiera.
4. Seleccione Aceptar y cierre el correo electrónico.
5.Seleccione Nuevo correo electrónico para ver la firma que creó.`,
        videoUrl:
          "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/outlook/Enviar+y+recibir+datos+adjuntos.mp4",
      },
      {
        id: "3",
        title: "Editar o reemplazar un correo",
        description: `
1. En la sección Insertar de la cinta de opciones, seleccione Firma > Firmas.
2. Seleccione Nueva, escriba un nombre para la firma y haga clic en Aceptar.
3. En Editar firma, escriba la firma y dele formato como quiera.
4. Seleccione Aceptar y cierre el correo electrónico.
5.Seleccione Nuevo correo electrónico para ver la firma que creó.`,
        videoUrl:
          "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/outlook/Editar+o+Recuperar+Correo.mp4",
      },
      {
        id: "4",
        title: "Crear citas y reuniones",
        description: `1. Seleccione Inicio > Nuevo correo electrónico.
        2. Agregue destinatarios, un tema y un mensaje en el cuerpo del correo electrónico.
        3. Seleccione Enviar.`,
        videoUrl:
          "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/outlook/Crear+citas+y+reuniones.mp4",
      },
      {
        id: "5",
        title: "Enviar y recibir datos adjuntos",
        description: `
1. En la sección Insertar de la cinta de opciones, seleccione Firma > Firmas.
2. Seleccione Nueva, escriba un nombre para la firma y haga clic en Aceptar.
3. En Editar firma, escriba la firma y dele formato como quiera.
4. Seleccione Aceptar y cierre el correo electrónico.
5.Seleccione Nuevo correo electrónico para ver la firma que creó.`,
        videoUrl:
          "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/outlook/Programar+reuinion.mp4",
      },
    ]/* ,
    Articles: [
      {
        id: 1,
        title: "Cómo agregar una confirmación de lectura en Outlook",
        tag: "Outlook",
        content:
          "Outlook ofrece la opción de solicitar confirmaciones de lectura para asegurarte de que tus correos han sido abiertos por el destinatario.\n\n## Pasos para habilitar la confirmación de lectura\n\n1. Abre Outlook y redacta un nuevo correo electrónico.\n2. Antes de enviar el correo, ve a la pestaña **Opciones** en la barra superior.\n3. Activa la casilla **Solicitar una confirmación de lectura**.\n\n### Nota\n\nNo todos los destinatarios tienen activada la función de envío de confirmaciones, por lo que es posible que no recibas una respuesta.",
        image: "https://example.com/image-outlook1.jpg",
        date: "2025-01-01",
      },
      {
        id: 2,
        title: "Cómo trabajar en un archivo compartido con Outlook",
        tag: "Outlook",
        content:
          "Outlook facilita la colaboración en archivos compartidos, integrándose con OneDrive y SharePoint.\n\n## Pasos para colaborar en un archivo\n\n1. Adjunta un archivo desde OneDrive o SharePoint en tu correo electrónico.\n2. Asegúrate de configurar los permisos para los destinatarios, como **pueden editar** o **pueden ver**.\n3. Envía el correo y verifica que los destinatarios reciban un enlace al archivo compartido.\n\n### Ventajas\n\n- **Edición en tiempo real**: Los cambios realizados por los colaboradores se reflejan automáticamente.\n- **Historial de versiones**: Consulta cambios anteriores desde OneDrive o SharePoint.",
        image: "https://example.com/image-outlook2.jpg",
        date: "2025-01-02",
      },
      {
        id: 3,
        title: "Cómo cambiar la contraseña de tu cuenta corporativa en Outlook",
        tag: "Outlook",
        content:
          "Si necesitas cambiar la contraseña de tu cuenta corporativa, sigue estos pasos:\n\n## Cambiar la contraseña desde tu cuenta corporativa\n\n1. Accede al portal de tu organización (como **Microsoft 365** o un portal interno).\n2. Ve a la sección **Mi cuenta** o **Seguridad**.\n3. Selecciona **Cambiar contraseña** y sigue las instrucciones.\n\n## Actualizar la contraseña en Outlook\n\n1. Abre Outlook en tu dispositivo.\n2. Ve a **Archivo > Configuración de cuenta**.\n3. Actualiza tu nueva contraseña en la configuración de la cuenta.\n\n### Consejos de seguridad\n\n- Usa una contraseña segura que combine letras, números y símbolos.\n- Cambia tu contraseña regularmente para proteger tu cuenta.",
        image: "https://example.com/image-outlook3.jpg",
        date: "2025-01-03",
      },
    ], */
  },
  Googledrive:{
    Videos: [
      {
        id: "1",
        title: "Tutorial de Google Drive",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/google-drive/Tutorial+Google+Drive.mp4",
      },
      {
        id: "2",
        title: "Google Drive para Android",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/google-drive/Google+Drive+para+Android.mp4",
      }
    ],
    Files:[
      {
        id: "1",
        name: "Como usar Google Drive",
        type: "PDF",
        url: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/google-drive/Como+usar+Google+Drive.pdf",
      }
    ]
  },
  Ivms4200:{
    
     Videos: [
      {
        id: "1",
        title: "Como ver camaras en vivo",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Como+ver+camaras+en+vivo.mp4",
      },
      {
        id: "2",
        title: "Visualizar camaras en linea",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Visualizar+camaras+en+linea.mp4",
      },
      {
        id: "3",
        title: "Reproducir Grabaciones del IVMS",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Reproducir+Grabaciones+del+IVMS.mp4",
      },
     
    ],
    Files:[
      {
        id: "1",
        name: "Manual de Usuario IVMS",
        type: "PDF",
        url: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Manual+de+uso+IVMS.pdf",
      }
    ] 
  },
  Smartpss:{/* 
    
    Videos: [
      {
        id: "1",
        title: "Tutorial de Google Drive",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/google-drive/Tutorial+Google+Drive.mp4",
      },
      {
        id: "2",
        title: "Google Drive para Android",
        description: ``,
        videoUrl: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/google-drive/Google+Drive+para+Android.mp4",
      }
    ],
    Files:[
      {
        id: "1",
        name: "Como usar Google Drive",
        type: "PDF",
        url: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Academy/google-drive/Como+usar+Google+Drive.pdf",
      }
    ] */
  },
  Dashboard:{
    Files:[
      {
        id: "1",
        name: "Dashboard - Manual de usuario",
        type: "PDF",
        url: "https://innova-bucket.s3.us-east-1.amazonaws.com/Assets/Dashboard+Manual.pdf",
      }
    ]
  }
};

const ResourcePage = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [colorTitle, setColorTitle] = useState("");
  // Redirige a la ruta de "videos" inicialmente si no se especifica
  useEffect(() => {
    if (
      !location.pathname.includes("/videos") &&
      !location.pathname.includes("/articles") &&
      !location.pathname.includes("/files")
    ) {
      navigate(`/dashboard/resources/${category}/videos`);
    }
    setColorTitle(getCategoryColor(category));
    console.log(getCategoryColor(category));
  }, [category, location, navigate]);

  // Sincroniza el valor de la pestaña con la URL
  useEffect(() => {
    if (location.pathname.includes("/articles")) {
      setValue(1);
    } else if (location.pathname.includes("/videos")) {
      setValue(0);
    } else {
      setValue(2);
    }
  }, [location]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate(`/dashboard/resources/${category}/videos`);
    } else if (newValue === 1) {
      navigate(`/dashboard/resources/${category}/articles`);
    } else {
      navigate(`/dashboard/resources/${category}/files`);
    }
  };

  const [value, setValue] = React.useState(0);

  console.log('category')
  console.log(category)
  console.log(category.charAt(0).toUpperCase() + category.slice(1))
  const resource =
    resourcesData[category.charAt(0).toUpperCase() + category.slice(1)];

  console.log("resource");
  console.log(resource);

  const videos = resource?.Videos || [];
  const articles = resource?.Articles || [];
  const files = resource?.Files || [];

  const getCategoryColor = (category) => {
    switch (category.toLowerCase()) {
      case "teams":
        return "#6264A7"; // Color oficial de Microsoft Teams
      case "outlook":
        return "#0078D4"; // Azul oficial de Microsoft Outlook
      case "googledrive":
        return "#34A853"; // Verde de Google Drive
      case "ivms4200":
        return "#D32F2F"; // Rojo (genérico, puedes cambiarlo por un color más específico)
      case "smartpss":
        return "#393c3e"; // Azul estándar
      case "dashboard":
        return "#835c0e"; // Dorado para Innova
      default:
        return "#CCCCCC"; // Color gris por defecto
    }
  };
  
  return (
    <div>
      <div class="flex justify-between items-center px-4 py-2 shadow-sm rounded-md mx-5">
        <h1 className="typewriter-text-secondary">
          {"Recursos de " +
            category.charAt(0).toUpperCase() +
            category.slice(1)}
        </h1>
        <h1 className="h3 font-semibold text-gray-700 text-xl flex flex-col mb-6 justify-center items-center">
          <img className={`h-9 w-11`} src={Logo} alt="Logo" />
          Innova Academy
        </h1>
        <div class="flex gap-2">
          <button class="flex items-center px-3 py-1 border border-blue-300 rounded-md bg-[#0078D4] text-gray-100 focus:ring-2 focus:ring-blue-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
            Agregar
          </button>

          <button class="flex items-center px-3 py-1 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 13H5m7 7V5"
              />
            </svg>
            Eliminar
          </button>

          <button class="flex items-center px-3 py-1 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-yellow-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L7.5 21H3v-4.5L16.732 3.732z"
              />
            </svg>
            Editar
          </button>
        </div>
      </div>
      
      <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleTabChange}
        centered
        sx={{
          "& .MuiTab-root.Mui-selected": {
            color: colorTitle, // Cambiar color del texto activo según la categoría
          },
        }}
      >
        {/* Tab de Videos */}
        <Tab
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Badge 
                badgeContent={videos.length} 
                color="error" // Badge rojo
                sx={{
                  marginRight: 1, 
                  '& .MuiBadge-dot': { backgroundColor: 'red' }, // Rojo para el badge
                  '& .MuiBadge-dot .MuiBadge-dot': { color: 'white' }, // Blanco para el texto del badge
                }}
              >
                <VideoCameraFront sx={{ color: colorTitle }} />
              </Badge>
              <Typography variant="body2" sx={{ color: colorTitle }}>Videos</Typography>
            </Box>
          }
        />

        {/* Tab de Articles */}
        <Tab
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Badge 
                badgeContent={articles.length} 
                color="error" // Badge rojo
                sx={{
                  marginRight: 1,
                  '& .MuiBadge-dot': { backgroundColor: 'red' }, // Rojo para el badge
                }}
              >
                <Article sx={{ color: colorTitle }} />
              </Badge>
              <Typography variant="body2" sx={{ color: colorTitle }}>Artículos</Typography>
            </Box>
          }
        />

        {/* Tab de Files */}
        <Tab
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Badge 
                badgeContent={files.length} 
                color="error" // Badge rojo
                sx={{
                  marginRight: 1,
                  '& .MuiBadge-dot': { backgroundColor: 'red' }, // Rojo para el badge
                }}
              >
                <FileCopy sx={{ color: colorTitle }} />
              </Badge>
              <Typography variant="body2" sx={{ color: colorTitle }}>Archivos</Typography>
            </Box>
          }
        />
      </Tabs>
    </Box>
      <Container sx={{ py: "20px" }}>
        <Routes>
          <Route
            path="videos"
            element={
              videos && (
                <>
                  <VideosComponent videos={videos} colorTitle={colorTitle} />
                </>
              )
            }
          />
          <Route
            path="articles"
            element={
              articles && (
                <>
                  <ArticlesComponent
                    articles={articles}
                    colorTitle={colorTitle}
                  />{" "}
                </>
              )
            }
          />
          <Route
            path="files"
            element={
              articles && (
                <>
                  <FilesResourcesComponent files={files} />{" "}
                </>
              )
            }
          />
        </Routes>
      </Container>
    </div>
  );
};

export default ResourcePage;
