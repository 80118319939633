import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { AiOutlineFilePdf, AiOutlineDownload } from "react-icons/ai"; // Usa icons de react-icons
import { useState } from "react";

const FilesResourcesComponent = ({files}) => {
  
  const [previewFile, setPreviewFile] = useState(null);

  

  const handlePreview = (fileUrl) => {
    setPreviewFile(fileUrl);
  };

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = true;
    link.click();
  };

  
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Archivos Disponibles
      </Typography>
      <Grid container spacing={3}>
        {files.map((file) => (
          <Grid item xs={12} sm={6} md={4} key={file.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{file.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {file.type}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}>
                  {/* Icono de archivo */}
                  <IconButton
                    onClick={() => handlePreview(file.url)}
                    sx={{
                      color: "#b81414",
                    }}
                  >
                    <AiOutlineFilePdf />
                  </IconButton>
                  {/* Botón para descargar */}
                  <IconButton
                    onClick={() => handleDownload(file.url)}
                    color="primary">
                    <AiOutlineDownload />
                  </IconButton>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Ventana de vista previa si se selecciona un archivo */}
      {previewFile && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: "1000",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
            }}
          >
            <Button
              onClick={() => setPreviewFile(null)}
              variant="contained"
              color="primary"
              style={{ marginBottom: "10px" }}
            >
              Cerrar Vista Previa
            </Button>
            <iframe
              src={previewFile}
              width="900"
              height="600"
              title="Vista Previa"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilesResourcesComponent;
