import React, { useContext } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';

export const GridEditReportTemplate = (props) => {
    console.log("props")
    console.log(props)
    const { setReportForm } = useContext(UserContext);
    const navigate = useNavigate();

    const parseDate = (dateStr) => {
        if (!dateStr) return null;
        const [month, day, year] = dateStr.split('-');
        return new Date(year, month - 1, day);
    };

    const parseTime = (timeStr, dateStr) => {
        if (!timeStr || !dateStr) return null;
        const [hours, minutes] = timeStr.split(':');
        const [month, day, year] = dateStr.split('-');
        return new Date(year, month - 1, day, hours, minutes);
    };

    const formatReportData = (report) => ({
        ...report,
        dateOfReport: parseDate(report.dateOfReport),
        timeOfReport: parseTime(report.timeOfReport, report.dateOfReport),
        incidentDate: parseDate(report.incidentDate),
        incidentStartTime: parseTime(report.incidentStartTime, report.incidentDate),
        incidentEndTime: parseTime(report.incidentEndTime, report.incidentDate),
    });

    const fetchReportById = async (reportId) => {
        try {
            const response = await fetch(`https://localhost:8080/api/reports/${reportId}`);
            if (!response.ok) {
                throw new Error('Error al obtener el reporte');
            }
            const reportData = await response.json();
            return reportData;
        } catch (error) {
            console.error('Error fetching report:', error);
            return null;
        }
    };

    const handleEditClick = async () => {
        try {
            let reportData = props;

            if (!props || !props.id) {
                if (!props.reportId) {
                    console.warn("No se proporcionaron datos ni ID para el reporte.");
                    return;
                }
                reportData = await fetchReportById(props.reportId);
                if (!reportData) {
                    console.warn("No se pudo obtener el reporte desde el servidor.");
                    return;
                }
            }

            const formattedProps = formatReportData(reportData);
            console.log("GridEditReportTemplate formattedProps:", formattedProps);

            setReportForm(formattedProps);
            navigate('/dashboard/EditReport');
        } catch (error) {
            console.error("Error handling edit click:", error);
        }
    };

    /* if (!props || (!props.id && !props.reportId)) {
        return <div className="flex justify-center"><p>No hay datos</p></div>;
    } */

    return (
        <div className="flex justify-center m-0 p-0 cursor-pointer" onClick={handleEditClick}>
            <AiFillEdit className="text-lg" />
        </div>
    );
};
