import React, { useState } from "react";
import { Grid, Card, CardContent, Typography, CardActions, Button, Box, Modal } from "@mui/material";
import { motion } from "framer-motion";

const ArticlesComponent = ({ articles, colorTitle }) => {
  const [open, setOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArticle(null);
  };

  return (
    <div>
      <Grid container spacing={3}>
        {articles.map((article, index) => (
          <Grid item xs={12} sm={8} md={6} key={index}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <Card
                elevation={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <CardContent>
                  <Typography variant="caption" sx={{color:colorTitle}} gutterBottom>
                    {article.tag}
                  </Typography>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      position: "relative",
                      textDecoration: "none",
                      cursor: "pointer",
                      "&:hover::before": {
                        content: '""',
                        position: "absolute",
                        width: "100%",
                        height: "1px",
                        bottom: 0,
                        left: 0,
                        backgroundColor: "currentColor",
                        opacity: 0.7,
                        transition: "width 0.3s ease",
                      },
                    }}
                  >
                    {article.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                    }}
                  >
                    {article.content.split("\n\n")[0]} {/* Primer párrafo */}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardActions>
                      <Button size="small" sx={{color:colorTitle}} onClick={() => handleOpen(article)}>
                        Leer más
                      </Button>
                    </CardActions>
                    <Typography variant="caption">{article.date}</Typography>
                  </Box>
                </Box>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Modal para mostrar detalles del artículo */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflowY: "auto",
            borderRadius: 2,
          }}
        >
          {selectedArticle && (
            <>
              <Typography variant="h4" gutterBottom>
                {selectedArticle.title}
              </Typography>
              <Typography variant="caption" color="text.secondary" gutterBottom>
                {selectedArticle.tag} - {selectedArticle.date}
              </Typography>
              {selectedArticle.image && (
                <Box
                  component="img"
                  src={selectedArticle.image}
                  alt={selectedArticle.title}
                  sx={{ width: "100%", borderRadius: 2, mb: 3 }}
                />
              )}
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  lineHeight: 1.6,
                }}
              >
                {selectedArticle.content}
              </Typography>
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ mt: 3, display: "block", marginLeft: "auto" }}
              >
                Cerrar
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ArticlesComponent;
